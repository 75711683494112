export const FEDNOW_MESSAGES = {
    "admi.002.001.01": {
        "title": "admi.002.001.01",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:admi.002.001.01\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:admi.002.001.01 MessageReject_admi.002.001.01.xsd\">\n" +
            "\t<admi.002.001.01>\n" +
            "\t\t<RltdRef>\n" +
            "\t\t\t<Ref>NOTAVAILABLE</Ref>\n" +
            "\t\t</RltdRef>\n" +
            "\t\t<Rsn>\n" +
            "\t\t\t<RjctgPtyRsn>T505</RjctgPtyRsn>\n" +
            "\t\t\t<RjctnDtTm>2023-07-26T11:35:24-04:00</RjctnDtTm>\n" +
            "\t\t</Rsn>\n" +
            "\t</admi.002.001.01>\n" +
            "</Document>"
    },
    "admi.007.001.01": {
        "title": "admi.007.001.01",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:admi.007.001.01\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:admi.007.001.01 ReceiptAcknowledgment_admi.007.001.01.xsd\">\n" +
            "\t<RctAck>\n" +
            "\t\t<MsgId>\n" +
            "\t\t\t<MsgId>20230714021150706FNACSc01Step1a</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-07-14T09:17:50-04:00</CreDtTm>\n" +
            "\t\t</MsgId>\n" +
            "\t\t<Rpt>\n" +
            "\t\t\t<RltdRef>\n" +
            "\t\t\t\t<Ref>20230714011104238RFPSc01Step1MsgId</Ref>\n" +
            "\t\t\t\t<MsgNm>pain.013.001.07</MsgNm>\n" +
            "\t\t\t</RltdRef>\n" +
            "\t\t\t<ReqHdlg>\n" +
            "\t\t\t\t<StsCd>TS01</StsCd>\n" +
            "\t\t\t</ReqHdlg>\n" +
            "\t\t</Rpt>\n" +
            "\t</RctAck>\n" +
            "</Document>"
    },
    "camt.029.001.09": {
        "title": "camt.029.001.09 (Return Request Response)",
        "fedNowMessageType": "CAMT_029_RETURN_REQUEST_RESPONSE",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.029.001.09\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.029.001.09 ReturnRequestResponse_camt_029_001_09.xsd\">\n" +
            "\t<RsltnOfInvstgtn>\n" +
            "\t\t<Assgnmt>\n" +
            "\t\t\t<Id>20230604021040078Sc01RTStep3MsgId</Id>\n" +
            "\t\t\t<Assgnr>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t<MmbId>021040078</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</Assgnr>\n" +
            "\t\t\t<Assgne>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</Assgne>\n" +
            "\t\t\t<CreDtTm>2023-06-04T14:55:26-04:00</CreDtTm>\n" +
            "\t\t</Assgnmt>\n" +
            "\t\t<RslvdCase>\n" +
            "\t\t\t<Id>20230604011104238Sc01Step1MsgIdDUP</Id>\n" +
            "\t\t\t<Cretr>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</Cretr>\n" +
            "\t\t</RslvdCase>\n" +
            "\t\t<Sts>\n" +
            "\t\t\t<Conf>IPAY</Conf>\n" +
            "\t\t</Sts>\n" +
            "\t\t<CxlDtls>\n" +
            "\t\t\t<TxInfAndSts>\n" +
            "\t\t\t\t<OrgnlGrpInf>\n" +
            "\t\t\t\t\t<OrgnlMsgId>20230604011104238Sc01RTStep1MsgId</OrgnlMsgId>\n" +
            "\t\t\t\t\t<OrgnlMsgNmId>pacs.008.001.08</OrgnlMsgNmId>\n" +
            "\t\t\t\t\t<OrgnlCreDtTm>2023-06-04T10:55:26-04:00</OrgnlCreDtTm>\n" +
            "\t\t\t\t</OrgnlGrpInf>\n" +
            "\t\t\t\t<OrgnlInstrId>Scenario01InstrId001</OrgnlInstrId>\n" +
            "\t\t\t\t<OrgnlEndToEndId>Scenario01EtoEId001</OrgnlEndToEndId>\n" +
            "\t\t\t\t<OrgnlUETR>8a562c67-ca16-48ba-b074-65581be6f011</OrgnlUETR>\n" +
            "\t\t\t\t<RsltnRltdInf>\n" +
            "\t\t\t\t\t<UETR>8a562c67-ca16-48ba-b074-65581be6f011</UETR>\n" +
            "\t\t\t\t\t<IntrBkSttlmAmt Ccy=\"USD\">51.74</IntrBkSttlmAmt>\n" +
            "\t\t\t\t\t<IntrBkSttlmDt>2023-06-04</IntrBkSttlmDt>\n" +
            "\t\t\t\t</RsltnRltdInf>\n" +
            "\t\t\t</TxInfAndSts>\n" +
            "\t\t</CxlDtls>\n" +
            "\t</RsltnOfInvstgtn>\n" +
            "</Document>"
    },
    "camt.052.001.08.account.activity.totals.report": {
        "title": "camt.052.001.08 (Account Activity Totals Report)",
        "fedNowMessageType": "CAMT_052_ACCOUNT_ACTIVITY_TOTALS_REPORT",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.052.001.08\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.052.001.08 FedNow_AccountActivityTotalsReport_camt_052_001_08.xsd\">\n" +
            "\t<BkToCstmrAcctRpt>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>CITR</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-09-22T17:31:30-04:00</CreDtTm>\n" +
            "\t\t\t<MsgPgntn>\n" +
            "\t\t\t\t<PgNb>1</PgNb>\n" +
            "\t\t\t\t<LastPgInd>true</LastPgInd>\n" +
            "\t\t\t</MsgPgntn>\n" +
            "\t\t\t<OrgnlBizQry>\n" +
            "\t\t\t\t<MsgId>20230922122240120CITRrequest1</MsgId>\n" +
            "\t\t\t\t<MsgNmId>camt.060.001.05</MsgNmId>\n" +
            "\t\t\t\t<CreDtTm>2023-09-22T15:58:23-04:00</CreDtTm>\n" +
            "\t\t\t</OrgnlBizQry>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<Rpt>\n" +
            "\t\t\t<Id>IDAY</Id>\n" +
            "\t\t\t<CreDtTm>2023-09-22T00:00:00-04:00</CreDtTm>\n" +
            "\t\t\t<Acct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t<Id>122240120</Id>\n" +
            "\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t</Acct>\n" +
            "\t\t\t<RltdAcct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t<Id>113185029</Id>\n" +
            "\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t</RltdAcct>\n" +
            "\t\t\t<TxsSummry>\n" +
            "\t\t\t\t<TtlCdtNtries>\n" +
            "\t\t\t\t\t<NbOfNtries>1</NbOfNtries>\n" +
            "\t\t\t\t\t<Sum>562</Sum>\n" +
            "\t\t\t\t</TtlCdtNtries>\n" +
            "\t\t\t\t<TtlDbtNtries>\n" +
            "\t\t\t\t\t<NbOfNtries>2</NbOfNtries>\n" +
            "\t\t\t\t\t<Sum>58.21</Sum>\n" +
            "\t\t\t\t</TtlDbtNtries>\n" +
            "\t\t\t</TxsSummry>\n" +
            "\t\t</Rpt>\n" +
            "\t\t<Rpt>\n" +
            "\t\t\t<Id>IDAY</Id>\n" +
            "\t\t\t<CreDtTm>2023-09-22T00:00:00-04:00</CreDtTm>\n" +
            "\t\t\t<Acct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t<Id>122240120</Id>\n" +
            "\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t</Acct>\n" +
            "\t\t\t<RltdAcct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t<Id>113194159</Id>\n" +
            "\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t</RltdAcct>\n" +
            "\t\t\t<TxsSummry>\n" +
            "\t\t\t\t<TtlCdtNtries>\n" +
            "\t\t\t\t\t<NbOfNtries>1</NbOfNtries>\n" +
            "\t\t\t\t\t<Sum>257.21</Sum>\n" +
            "\t\t\t\t</TtlCdtNtries>\n" +
            "\t\t\t\t<TtlDbtNtries>\n" +
            "\t\t\t\t\t<NbOfNtries>1</NbOfNtries>\n" +
            "\t\t\t\t\t<Sum>58.12</Sum>\n" +
            "\t\t\t\t</TtlDbtNtries>\n" +
            "\t\t\t</TxsSummry>\n" +
            "\t\t</Rpt>\n" +
            "\t</BkToCstmrAcctRpt>\n" +
            "</Document>"
    },
    "camt.052.001.08.account.activity.details.report": {
        "title": "camt.052.001.08  (Account Activity Details Report)",
        "fedNowMessageType": "CAMT_052_ACCOUNT_ACTIVITY_DETAILS_REPORT",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.052.001.08\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.052.001.08 FedNow_AccountActivityDetailsReport_camt_052_001_08.xsd\">\n" +
            "\t<BkToCstmrAcctRpt>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>AADR</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-09-02T19:31:13-04:00</CreDtTm>\n" +
            "\t\t\t<MsgPgntn>\n" +
            "\t\t\t\t<PgNb>1</PgNb>\n" +
            "\t\t\t\t<LastPgInd>true</LastPgInd>\n" +
            "\t\t\t</MsgPgntn>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<Rpt>\n" +
            "\t\t\t<Id>EDAY</Id>\n" +
            "\t\t\t<CreDtTm>2023-09-02T00:00:00-04:00</CreDtTm>\n" +
            "\t\t\t<Acct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t<Id>011104238</Id>\n" +
            "\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t</Acct>\n" +
            "\t\t\t<TxsSummry>\n" +
            "\t\t\t\t<TtlCdtNtries>\n" +
            "\t\t\t\t\t<NbOfNtries>2</NbOfNtries>\n" +
            "\t\t\t\t\t<Sum>1000.59</Sum>\n" +
            "\t\t\t\t</TtlCdtNtries>\n" +
            "\t\t\t\t<TtlDbtNtries>\n" +
            "\t\t\t\t\t<NbOfNtries>1</NbOfNtries>\n" +
            "\t\t\t\t\t<Sum>56</Sum>\n" +
            "\t\t\t\t</TtlDbtNtries>\n" +
            "\t\t\t\t<TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t\t<NbOfNtries>1</NbOfNtries>\n" +
            "\t\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t<Cd>RJTS</Cd>\n" +
            "\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t</TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t<TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t\t<NbOfNtries>0</NbOfNtries>\n" +
            "\t\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t<Cd>RJTR</Cd>\n" +
            "\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t</TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t<TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t\t<NbOfNtries>2</NbOfNtries>\n" +
            "\t\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t<Cd>SENT</Cd>\n" +
            "\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t</TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t<TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t\t<NbOfNtries>1</NbOfNtries>\n" +
            "\t\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t<Cd>RCVD</Cd>\n" +
            "\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t</TtlNtriesPerBkTxCd>\n" +
            "\t\t\t</TxsSummry>\n" +
            "\t\t\t<Ntry>\n" +
            "\t\t\t\t<Amt Ccy=\"USD\">410.60</Amt>\n" +
            "\t\t\t\t<CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "\t\t\t\t<Sts>\n" +
            "\t\t\t\t\t<Cd>BOOK</Cd>\n" +
            "\t\t\t\t</Sts>\n" +
            "\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t<Cd>CRDT</Cd>\n" +
            "\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t<AddtlInfInd>\n" +
            "\t\t\t\t\t<MsgNmId>pacs.008.001.08</MsgNmId>\n" +
            "\t\t\t\t</AddtlInfInd>\n" +
            "\t\t\t\t<NtryDtls>\n" +
            "\t\t\t\t\t<TxDtls>\n" +
            "\t\t\t\t\t\t<Refs>\n" +
            "\t\t\t\t\t\t\t<MsgId>20230902231981435reportdata1</MsgId>\n" +
            "\t\t\t\t\t\t\t<InstrId>20230902231981435InstrId001</InstrId>\n" +
            "\t\t\t\t\t\t\t<EndToEndId>20230902231981435E2EId001</EndToEndId>\n" +
            "\t\t\t\t\t\t\t<UETR>8a562c67-ca16-48ba-b074-65581be6f011</UETR>\n" +
            "\t\t\t\t\t\t</Refs>\n" +
            "\t\t\t\t\t\t<RltdAgts>\n" +
            "\t\t\t\t\t\t\t<InstgAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>231981435</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstgAgt>\n" +
            "\t\t\t\t\t\t\t<InstdAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstdAgt>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>INDA</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t</Agt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdAgts>\n" +
            "\t\t\t\t\t\t<LclInstrm>\n" +
            "\t\t\t\t\t\t\t<Prtry>FDNA</Prtry>\n" +
            "\t\t\t\t\t\t</LclInstrm>\n" +
            "\t\t\t\t\t\t<RltdDts>\n" +
            "\t\t\t\t\t\t\t<AccptncDtTm>2023-09-02T09:48:02-04:00</AccptncDtTm>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>CDTM</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t\t\t\t<DtTm>2023-09-02T09:48:02-04:00</DtTm>\n" +
            "\t\t\t\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>BPRD</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t\t\t\t<DtTm>2023-09-02T09:48:02-04:00</DtTm>\n" +
            "\t\t\t\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdDts>\n" +
            "\t\t\t\t\t</TxDtls>\n" +
            "\t\t\t\t</NtryDtls>\n" +
            "\t\t\t</Ntry>\n" +
            "\t\t\t<Ntry>\n" +
            "\t\t\t\t<Amt Ccy=\"USD\">589.99</Amt>\n" +
            "\t\t\t\t<CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "\t\t\t\t<Sts>\n" +
            "\t\t\t\t\t<Cd>BOOK</Cd>\n" +
            "\t\t\t\t</Sts>\n" +
            "\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t<Cd>CRDT</Cd>\n" +
            "\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t<AddtlInfInd>\n" +
            "\t\t\t\t\t<MsgNmId>pacs.008.001.08</MsgNmId>\n" +
            "\t\t\t\t</AddtlInfInd>\n" +
            "\t\t\t\t<NtryDtls>\n" +
            "\t\t\t\t\t<TxDtls>\n" +
            "\t\t\t\t\t\t<Refs>\n" +
            "\t\t\t\t\t\t\t<MsgId>20230902SP1234455reportdata2</MsgId>\n" +
            "\t\t\t\t\t\t\t<InstrId>20230902SP1234455InstrId001</InstrId>\n" +
            "\t\t\t\t\t\t\t<EndToEndId>20230902SP1234455E2EId001</EndToEndId>\n" +
            "\t\t\t\t\t\t\t<UETR>8a533c67-ca16-48de-b074-65581be6f011</UETR>\n" +
            "\t\t\t\t\t\t</Refs>\n" +
            "\t\t\t\t\t\t<RltdAgts>\n" +
            "\t\t\t\t\t\t\t<InstgAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>114000116</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstgAgt>\n" +
            "\t\t\t\t\t\t\t<InstdAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstdAgt>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>INDA</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t</Agt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdAgts>\n" +
            "\t\t\t\t\t\t<LclInstrm>\n" +
            "\t\t\t\t\t\t\t<Prtry>FDNA</Prtry>\n" +
            "\t\t\t\t\t\t</LclInstrm>\n" +
            "\t\t\t\t\t\t<RltdDts>\n" +
            "\t\t\t\t\t\t\t<AccptncDtTm>2023-09-02T11:23:46-04:00</AccptncDtTm>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>CDTM</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t\t\t\t<DtTm>2023-09-02T11:23:46-04:00</DtTm>\n" +
            "\t\t\t\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>BPRD</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t\t\t\t<DtTm>2023-09-02T11:23:46-04:00</DtTm>\n" +
            "\t\t\t\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdDts>\n" +
            "\t\t\t\t\t</TxDtls>\n" +
            "\t\t\t\t</NtryDtls>\n" +
            "\t\t\t</Ntry>\n" +
            "\t\t\t<Ntry>\n" +
            "\t\t\t\t<Amt Ccy=\"USD\">56</Amt>\n" +
            "\t\t\t\t<CdtDbtInd>DBIT</CdtDbtInd>\n" +
            "\t\t\t\t<Sts>\n" +
            "\t\t\t\t\t<Cd>BOOK</Cd>\n" +
            "\t\t\t\t</Sts>\n" +
            "\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t<Cd>DBIT</Cd>\n" +
            "\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t<AddtlInfInd>\n" +
            "\t\t\t\t\t<MsgNmId>pacs.008.001.08</MsgNmId>\n" +
            "\t\t\t\t</AddtlInfInd>\n" +
            "\t\t\t\t<NtryDtls>\n" +
            "\t\t\t\t\t<TxDtls>\n" +
            "\t\t\t\t\t\t<Refs>\n" +
            "\t\t\t\t\t\t\t<MsgId>20230902011104238reportdata3</MsgId>\n" +
            "\t\t\t\t\t\t\t<InstrId>20230902011104238InstrId001</InstrId>\n" +
            "\t\t\t\t\t\t\t<EndToEndId>20230902011104238E2EId001</EndToEndId>\n" +
            "\t\t\t\t\t\t\t<UETR>8a533c67-ca16-48de-b074-88767be6f023</UETR>\n" +
            "\t\t\t\t\t\t</Refs>\n" +
            "\t\t\t\t\t\t<RltdAgts>\n" +
            "\t\t\t\t\t\t\t<InstgAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstgAgt>\n" +
            "\t\t\t\t\t\t\t<InstdAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>021307481</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstdAgt>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>INGA</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t</Agt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdAgts>\n" +
            "\t\t\t\t\t\t<LclInstrm>\n" +
            "\t\t\t\t\t\t\t<Prtry>FDNA</Prtry>\n" +
            "\t\t\t\t\t\t</LclInstrm>\n" +
            "\t\t\t\t\t\t<RltdDts>\n" +
            "\t\t\t\t\t\t\t<AccptncDtTm>2023-09-02T14:59:33-04:00</AccptncDtTm>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>CDTM</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t\t\t\t<DtTm>2023-09-02T14:59:33-04:00</DtTm>\n" +
            "\t\t\t\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>BPRD</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t\t\t\t<DtTm>2023-09-02T14:59:33-04:00</DtTm>\n" +
            "\t\t\t\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdDts>\n" +
            "\t\t\t\t\t</TxDtls>\n" +
            "\t\t\t\t</NtryDtls>\n" +
            "\t\t\t</Ntry>\n" +
            "\t\t\t<Ntry>\n" +
            "\t\t\t\t<Amt Ccy=\"USD\">87.21</Amt>\n" +
            "\t\t\t\t<CdtDbtInd>DBIT</CdtDbtInd>\n" +
            "\t\t\t\t<Sts>\n" +
            "\t\t\t\t\t<Cd>RJCT</Cd>\n" +
            "\t\t\t\t</Sts>\n" +
            "\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t<Cd>RJTS</Cd>\n" +
            "\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t<AddtlInfInd>\n" +
            "\t\t\t\t\t<MsgNmId>pacs.008.001.08</MsgNmId>\n" +
            "\t\t\t\t</AddtlInfInd>\n" +
            "\t\t\t\t<NtryDtls>\n" +
            "\t\t\t\t\t<TxDtls>\n" +
            "\t\t\t\t\t\t<Refs>\n" +
            "\t\t\t\t\t\t\t<MsgId>20230902011104238reportdata4</MsgId>\n" +
            "\t\t\t\t\t\t\t<InstrId>20230902011104238InstrId002</InstrId>\n" +
            "\t\t\t\t\t\t\t<EndToEndId>20230902011104238E2EId002</EndToEndId>\n" +
            "\t\t\t\t\t\t</Refs>\n" +
            "\t\t\t\t\t\t<RltdAgts>\n" +
            "\t\t\t\t\t\t\t<InstgAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstgAgt>\n" +
            "\t\t\t\t\t\t\t<InstdAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>231981435</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstdAgt>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>INGA</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t</Agt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdAgts>\n" +
            "\t\t\t\t\t\t<LclInstrm>\n" +
            "\t\t\t\t\t\t\t<Prtry>FDNA</Prtry>\n" +
            "\t\t\t\t\t\t</LclInstrm>\n" +
            "\t\t\t\t\t\t<RltdDts>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>BPRD</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t\t\t\t<DtTm>2023-09-02T14:59:33-04:00</DtTm>\n" +
            "\t\t\t\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdDts>\n" +
            "\t\t\t\t\t\t<AddtlTxInf>E997</AddtlTxInf>\n" +
            "\t\t\t\t\t</TxDtls>\n" +
            "\t\t\t\t</NtryDtls>\n" +
            "\t\t\t</Ntry>\n" +
            "\t\t\t<Ntry>\n" +
            "\t\t\t\t<Amt Ccy=\"USD\">0</Amt>\n" +
            "\t\t\t\t<CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "\t\t\t\t<Sts>\n" +
            "\t\t\t\t\t<Cd>INFO</Cd>\n" +
            "\t\t\t\t</Sts>\n" +
            "\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t<Cd>SENT</Cd>\n" +
            "\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t<AddtlInfInd>\n" +
            "\t\t\t\t\t<MsgNmId>pain.013.001.07</MsgNmId>\n" +
            "\t\t\t\t</AddtlInfInd>\n" +
            "\t\t\t\t<NtryDtls>\n" +
            "\t\t\t\t\t<TxDtls>\n" +
            "\t\t\t\t\t\t<Refs>\n" +
            "\t\t\t\t\t\t\t<MsgId>20230902231981435reportdata5</MsgId>\n" +
            "\t\t\t\t\t\t</Refs>\n" +
            "\t\t\t\t\t\t<RltdAgts>\n" +
            "\t\t\t\t\t\t\t<InstgAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>231981435</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstgAgt>\n" +
            "\t\t\t\t\t\t\t<InstdAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstdAgt>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>INDA</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t</Agt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdAgts>\n" +
            "\t\t\t\t\t\t<RltdDts>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>CDTM</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t\t\t\t<DtTm>2023-09-02T10:08:02-04:00</DtTm>\n" +
            "\t\t\t\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdDts>\n" +
            "\t\t\t\t\t</TxDtls>\n" +
            "\t\t\t\t</NtryDtls>\n" +
            "\t\t\t</Ntry>\n" +
            "\t\t\t<Ntry>\n" +
            "\t\t\t\t<Amt Ccy=\"USD\">0</Amt>\n" +
            "\t\t\t\t<CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "\t\t\t\t<Sts>\n" +
            "\t\t\t\t\t<Cd>INFO</Cd>\n" +
            "\t\t\t\t</Sts>\n" +
            "\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t<Cd>SENT</Cd>\n" +
            "\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t<AddtlInfInd>\n" +
            "\t\t\t\t\t<MsgNmId>camt.029.001.09</MsgNmId>\n" +
            "\t\t\t\t</AddtlInfInd>\n" +
            "\t\t\t\t<NtryDtls>\n" +
            "\t\t\t\t\t<TxDtls>\n" +
            "\t\t\t\t\t\t<Refs>\n" +
            "\t\t\t\t\t\t\t<MsgId>20230902231981435reportdata6</MsgId>\n" +
            "\t\t\t\t\t\t</Refs>\n" +
            "\t\t\t\t\t\t<RltdAgts>\n" +
            "\t\t\t\t\t\t\t<InstgAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>231981435</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstgAgt>\n" +
            "\t\t\t\t\t\t\t<InstdAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstdAgt>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>INDA</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t</Agt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdAgts>\n" +
            "\t\t\t\t\t\t<RltdDts>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>CDTM</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t\t\t\t<DtTm>2023-09-02T16:09:28-04:00</DtTm>\n" +
            "\t\t\t\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdDts>\n" +
            "\t\t\t\t\t</TxDtls>\n" +
            "\t\t\t\t</NtryDtls>\n" +
            "\t\t\t</Ntry>\n" +
            "\t\t\t<Ntry>\n" +
            "\t\t\t\t<Amt Ccy=\"USD\">0</Amt>\n" +
            "\t\t\t\t<CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "\t\t\t\t<Sts>\n" +
            "\t\t\t\t\t<Cd>INFO</Cd>\n" +
            "\t\t\t\t</Sts>\n" +
            "\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t<Cd>RCVD</Cd>\n" +
            "\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t<AddtlInfInd>\n" +
            "\t\t\t\t\t<MsgNmId>camt.029.001.09</MsgNmId>\n" +
            "\t\t\t\t</AddtlInfInd>\n" +
            "\t\t\t\t<NtryDtls>\n" +
            "\t\t\t\t\t<TxDtls>\n" +
            "\t\t\t\t\t\t<Refs>\n" +
            "\t\t\t\t\t\t\t<MsgId>20230902011104238reportdata7</MsgId>\n" +
            "\t\t\t\t\t\t</Refs>\n" +
            "\t\t\t\t\t\t<RltdAgts>\n" +
            "\t\t\t\t\t\t\t<InstgAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstgAgt>\n" +
            "\t\t\t\t\t\t\t<InstdAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>021307481</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstdAgt>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>INGA</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t</Agt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdAgts>\n" +
            "\t\t\t\t\t\t<RltdDts>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>CDTM</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t\t\t\t<DtTm>2023-09-02T16:09:39-04:00</DtTm>\n" +
            "\t\t\t\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdDts>\n" +
            "\t\t\t\t\t</TxDtls>\n" +
            "\t\t\t\t</NtryDtls>\n" +
            "\t\t\t</Ntry>\n" +
            "\t\t</Rpt>\n" +
            "\t</BkToCstmrAcctRpt>\n" +
            "</Document>"
    },
    "camt.052.001.08.account.balance.report": {
        "title": "camt.052.001.08 (Account Balance Report)",
        "fedNowMessageType": "CAMT_052_ACCOUNT_BALANCE_REPORT",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.052.001.08\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.052.001.08 FedNow_AccountBalanceReport_camt_052_001_08.xsd\">\n" +
            "\t<BkToCstmrAcctRpt>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>ABAR</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-09-21T18:30:45-04:00</CreDtTm>\n" +
            "\t\t\t<MsgPgntn>\n" +
            "\t\t\t\t<PgNb>1</PgNb>\n" +
            "\t\t\t\t<LastPgInd>true</LastPgInd>\n" +
            "\t\t\t</MsgPgntn>\n" +
            "\t\t\t<OrgnlBizQry>\n" +
            "\t\t\t\t<MsgId>20230921231981435ABARMMrequest1</MsgId>\n" +
            "\t\t\t\t<MsgNmId>camt.060.001.05</MsgNmId>\n" +
            "\t\t\t\t<CreDtTm>2023-09-21T13:29:32-04:00</CreDtTm>\n" +
            "\t\t\t</OrgnlBizQry>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<Rpt>\n" +
            "\t\t\t<Id>ABMS</Id>\n" +
            "\t\t\t<CreDtTm>2023-09-21T00:00:00-04:00</CreDtTm>\n" +
            "\t\t\t<Acct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t<Id>231981435</Id>\n" +
            "\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t\t<Tp>\n" +
            "\t\t\t\t\t<Prtry>M</Prtry>\n" +
            "\t\t\t\t</Tp>\n" +
            "\t\t\t</Acct>\n" +
            "\t\t\t<RltdAcct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t<Id>231981435</Id>\n" +
            "\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t</RltdAcct>\n" +
            "\t\t\t<Bal>\n" +
            "\t\t\t\t<Tp>\n" +
            "\t\t\t\t\t<CdOrPrtry>\n" +
            "\t\t\t\t\t\t<Prtry>OBFL</Prtry>\n" +
            "\t\t\t\t\t</CdOrPrtry>\n" +
            "\t\t\t\t</Tp>\n" +
            "\t\t\t\t<Amt Ccy=\"USD\">8000000</Amt>\n" +
            "\t\t\t\t<CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t</Dt>\n" +
            "\t\t\t</Bal>\n" +
            "\t\t\t<Bal>\n" +
            "\t\t\t\t<Tp>\n" +
            "\t\t\t\t\t<CdOrPrtry>\n" +
            "\t\t\t\t\t\t<Prtry>DLOD</Prtry>\n" +
            "\t\t\t\t\t</CdOrPrtry>\n" +
            "\t\t\t\t</Tp>\n" +
            "\t\t\t\t<Amt Ccy=\"USD\">8576743.25</Amt>\n" +
            "\t\t\t\t<CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t</Dt>\n" +
            "\t\t\t</Bal>\n" +
            "\t\t\t<Bal>\n" +
            "\t\t\t\t<Tp>\n" +
            "\t\t\t\t\t<CdOrPrtry>\n" +
            "\t\t\t\t\t\t<Prtry>ABAL</Prtry>\n" +
            "\t\t\t\t\t</CdOrPrtry>\n" +
            "\t\t\t\t</Tp>\n" +
            "\t\t\t\t<CdtLine>\n" +
            "\t\t\t\t\t<Incl>true</Incl>\n" +
            "\t\t\t\t\t<Tp>\n" +
            "\t\t\t\t\t\t<Prtry>NCAP</Prtry>\n" +
            "\t\t\t\t\t</Tp>\n" +
            "\t\t\t\t\t<Amt Ccy=\"USD\">100</Amt>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</CdtLine>\n" +
            "\t\t\t\t<CdtLine>\n" +
            "\t\t\t\t\t<Incl>true</Incl>\n" +
            "\t\t\t\t\t<Tp>\n" +
            "\t\t\t\t\t\t<Prtry>CCAP</Prtry>\n" +
            "\t\t\t\t\t</Tp>\n" +
            "\t\t\t\t\t<Amt Ccy=\"USD\">100</Amt>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</CdtLine>\n" +
            "\t\t\t\t<CdtLine>\n" +
            "\t\t\t\t\t<Incl>true</Incl>\n" +
            "\t\t\t\t\t<Tp>\n" +
            "\t\t\t\t\t\t<Prtry>COLL</Prtry>\n" +
            "\t\t\t\t\t</Tp>\n" +
            "\t\t\t\t\t<Amt Ccy=\"USD\">100000</Amt>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</CdtLine>\n" +
            "\t\t\t\t<CdtLine>\n" +
            "\t\t\t\t\t<Incl>true</Incl>\n" +
            "\t\t\t\t\t<Tp>\n" +
            "\t\t\t\t\t\t<Prtry>CLOD</Prtry>\n" +
            "\t\t\t\t\t</Tp>\n" +
            "\t\t\t\t\t<Amt Ccy=\"USD\">1500000</Amt>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</CdtLine>\n" +
            "\t\t\t\t<CdtLine>\n" +
            "\t\t\t\t\t<Incl>true</Incl>\n" +
            "\t\t\t\t\t<Tp>\n" +
            "\t\t\t\t\t\t<Prtry>ULOD</Prtry>\n" +
            "\t\t\t\t\t</Tp>\n" +
            "\t\t\t\t\t<Amt Ccy=\"USD\">0</Amt>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</CdtLine>\n" +
            "\t\t\t\t<Amt Ccy=\"USD\">8571743.25</Amt>\n" +
            "\t\t\t\t<CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t</Dt>\n" +
            "\t\t\t</Bal>\n" +
            "\t\t\t<Bal>\n" +
            "\t\t\t\t<Tp>\n" +
            "\t\t\t\t\t<CdOrPrtry>\n" +
            "\t\t\t\t\t\t<Prtry>AVLD</Prtry>\n" +
            "\t\t\t\t\t</CdOrPrtry>\n" +
            "\t\t\t\t</Tp>\n" +
            "\t\t\t\t<Amt Ccy=\"USD\">8576943.25</Amt>\n" +
            "\t\t\t\t<CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t</Dt>\n" +
            "\t\t\t</Bal>\n" +
            "\t\t\t<TxsSummry>\n" +
            "\t\t\t\t<TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t\t<TtlNetNtry>\n" +
            "\t\t\t\t\t\t<Amt>10000</Amt>\n" +
            "\t\t\t\t\t\t<CdtDbtInd>DBIT</CdtDbtInd>\n" +
            "\t\t\t\t\t</TtlNetNtry>\n" +
            "\t\t\t\t\t<CdtNtries>\n" +
            "\t\t\t\t\t\t<NbOfNtries>100</NbOfNtries>\n" +
            "\t\t\t\t\t\t<Sum>50000</Sum>\n" +
            "\t\t\t\t\t</CdtNtries>\n" +
            "\t\t\t\t\t<DbtNtries>\n" +
            "\t\t\t\t\t\t<NbOfNtries>200</NbOfNtries>\n" +
            "\t\t\t\t\t\t<Sum>60000</Sum>\n" +
            "\t\t\t\t\t</DbtNtries>\n" +
            "\t\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t<Cd>FDWF</Cd>\n" +
            "\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t<TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t\t<TtlNetNtry>\n" +
            "\t\t\t\t\t\t<Amt>10000</Amt>\n" +
            "\t\t\t\t\t\t<CdtDbtInd>DBIT</CdtDbtInd>\n" +
            "\t\t\t\t\t</TtlNetNtry>\n" +
            "\t\t\t\t\t<CdtNtries>\n" +
            "\t\t\t\t\t\t<NbOfNtries>300</NbOfNtries>\n" +
            "\t\t\t\t\t\t<Sum>50000</Sum>\n" +
            "\t\t\t\t\t</CdtNtries>\n" +
            "\t\t\t\t\t<DbtNtries>\n" +
            "\t\t\t\t\t\t<NbOfNtries>100</NbOfNtries>\n" +
            "\t\t\t\t\t\t<Sum>60000</Sum>\n" +
            "\t\t\t\t\t</DbtNtries>\n" +
            "\t\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t<Cd>NSSE</Cd>\n" +
            "\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t<TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t\t<TtlNetNtry>\n" +
            "\t\t\t\t\t\t<Amt>10000</Amt>\n" +
            "\t\t\t\t\t\t<CdtDbtInd>DBIT</CdtDbtInd>\n" +
            "\t\t\t\t\t</TtlNetNtry>\n" +
            "\t\t\t\t\t<CdtNtries>\n" +
            "\t\t\t\t\t\t<NbOfNtries>100</NbOfNtries>\n" +
            "\t\t\t\t\t\t<Sum>50000</Sum>\n" +
            "\t\t\t\t\t</CdtNtries>\n" +
            "\t\t\t\t\t<DbtNtries>\n" +
            "\t\t\t\t\t\t<NbOfNtries>200</NbOfNtries>\n" +
            "\t\t\t\t\t\t<Sum>60000</Sum>\n" +
            "\t\t\t\t\t</DbtNtries>\n" +
            "\t\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t<Cd>FDWS</Cd>\n" +
            "\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t<TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t\t<TtlNetNtry>\n" +
            "\t\t\t\t\t\t<Amt>491743.25</Amt>\n" +
            "\t\t\t\t\t\t<CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "\t\t\t\t\t</TtlNetNtry>\n" +
            "\t\t\t\t\t<CdtNtries>\n" +
            "\t\t\t\t\t\t<NbOfNtries>1524</NbOfNtries>\n" +
            "\t\t\t\t\t\t<Sum>548695.35</Sum>\n" +
            "\t\t\t\t\t</CdtNtries>\n" +
            "\t\t\t\t\t<DbtNtries>\n" +
            "\t\t\t\t\t\t<NbOfNtries>1000</NbOfNtries>\n" +
            "\t\t\t\t\t\t<Sum>56952.10</Sum>\n" +
            "\t\t\t\t\t</DbtNtries>\n" +
            "\t\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t<Cd>FDNF</Cd>\n" +
            "\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t<TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t\t<TtlNetNtry>\n" +
            "\t\t\t\t\t\t<Amt>10000</Amt>\n" +
            "\t\t\t\t\t\t<CdtDbtInd>DBIT</CdtDbtInd>\n" +
            "\t\t\t\t\t</TtlNetNtry>\n" +
            "\t\t\t\t\t<CdtNtries>\n" +
            "\t\t\t\t\t\t<NbOfNtries>75</NbOfNtries>\n" +
            "\t\t\t\t\t\t<Sum>50000</Sum>\n" +
            "\t\t\t\t\t</CdtNtries>\n" +
            "\t\t\t\t\t<DbtNtries>\n" +
            "\t\t\t\t\t\t<NbOfNtries>150</NbOfNtries>\n" +
            "\t\t\t\t\t\t<Sum>60000</Sum>\n" +
            "\t\t\t\t\t</DbtNtries>\n" +
            "\t\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t<Cd>FDAP</Cd>\n" +
            "\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t<TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t\t<TtlNetNtry>\n" +
            "\t\t\t\t\t\t<Amt>12500</Amt>\n" +
            "\t\t\t\t\t\t<CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "\t\t\t\t\t</TtlNetNtry>\n" +
            "\t\t\t\t\t<CdtNtries>\n" +
            "\t\t\t\t\t\t<Sum>130000</Sum>\n" +
            "\t\t\t\t\t</CdtNtries>\n" +
            "\t\t\t\t\t<DbtNtries>\n" +
            "\t\t\t\t\t\t<Sum>5000</Sum>\n" +
            "\t\t\t\t\t</DbtNtries>\n" +
            "\t\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t<Cd>AVOT</Cd>\n" +
            "\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t<TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t\t<TtlNetNtry>\n" +
            "\t\t\t\t\t\t<Amt>5000</Amt>\n" +
            "\t\t\t\t\t\t<CdtDbtInd>DBIT</CdtDbtInd>\n" +
            "\t\t\t\t\t</TtlNetNtry>\n" +
            "\t\t\t\t\t<CdtNtries>\n" +
            "\t\t\t\t\t\t<Sum>1000</Sum>\n" +
            "\t\t\t\t\t</CdtNtries>\n" +
            "\t\t\t\t\t<DbtNtries>\n" +
            "\t\t\t\t\t\t<Sum>6000</Sum>\n" +
            "\t\t\t\t\t</DbtNtries>\n" +
            "\t\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t<Cd>UVOT</Cd>\n" +
            "\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t<TtlNtriesPerBkTxCd>\n" +
            "\t\t\t\t\t<TtlNetNtry>\n" +
            "\t\t\t\t\t\t<Amt>0</Amt>\n" +
            "\t\t\t\t\t\t<CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "\t\t\t\t\t</TtlNetNtry>\n" +
            "\t\t\t\t\t<CdtNtries>\n" +
            "\t\t\t\t\t\t<NbOfNtries>1</NbOfNtries>\n" +
            "\t\t\t\t\t\t<Sum>1000</Sum>\n" +
            "\t\t\t\t\t</CdtNtries>\n" +
            "\t\t\t\t\t<DbtNtries>\n" +
            "\t\t\t\t\t\t<NbOfNtries>1</NbOfNtries>\n" +
            "\t\t\t\t\t\t<Sum>1000</Sum>\n" +
            "\t\t\t\t\t</DbtNtries>\n" +
            "\t\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t<Cd>MEMO</Cd>\n" +
            "\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t\t<Dt>\n" +
            "\t\t\t\t\t\t<DtTm>2023-09-21T18:30:45-04:00</DtTm>\n" +
            "\t\t\t\t\t</Dt>\n" +
            "\t\t\t\t</TtlNtriesPerBkTxCd>\n" +
            "\t\t\t</TxsSummry>\n" +
            "\t\t</Rpt>\n" +
            "\t</BkToCstmrAcctRpt>\n" +
            "</Document>"
    },
    "camt.054.001.08": {
        "title": "camt.054.001.08",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.054.001.08\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.054.001.08 FedNow_AccountDebitCreditNotification_camt_054_001_08.xsd\">\n" +
            "\t<BkToCstmrDbtCdtNtfctn>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>ADCN</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-06-04T10:57:39-04:00</CreDtTm>\n" +
            "\t\t\t<MsgPgntn>\n" +
            "\t\t\t\t<PgNb>1</PgNb>\n" +
            "\t\t\t\t<LastPgInd>true</LastPgInd>\n" +
            "\t\t\t</MsgPgntn>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<Ntfctn>\n" +
            "\t\t\t<Id>FedNowServiceApplicationRef001</Id>\n" +
            "\t\t\t<Acct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t<Id>011104238</Id>\n" +
            "\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t</Acct>\n" +
            "\t\t\t<Ntry>\n" +
            "\t\t\t\t<Amt Ccy=\"USD\">51.74</Amt>\n" +
            "\t\t\t\t<CdtDbtInd>DBIT</CdtDbtInd>\n" +
            "\t\t\t\t<Sts>\n" +
            "\t\t\t\t\t<Cd>BOOK</Cd>\n" +
            "\t\t\t\t</Sts>\n" +
            "\t\t\t\t<BkTxCd>\n" +
            "\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t<Cd>DBIT</Cd>\n" +
            "\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t</BkTxCd>\n" +
            "\t\t\t\t<AddtlInfInd>\n" +
            "\t\t\t\t\t<MsgNmId>pacs.008.001.08</MsgNmId>\n" +
            "\t\t\t\t</AddtlInfInd>\n" +
            "\t\t\t\t<NtryDtls>\n" +
            "\t\t\t\t\t<TxDtls>\n" +
            "\t\t\t\t\t\t<Refs>\n" +
            "\t\t\t\t\t\t\t<MsgId>20230604011104238Sc01Step1MsgId</MsgId>\n" +
            "\t\t\t\t\t\t\t<InstrId>Scenario01InstrId001</InstrId>\n" +
            "\t\t\t\t\t\t\t<EndToEndId>Scenario01EtoEId001</EndToEndId>\n" +
            "\t\t\t\t\t\t\t<UETR>8a562c67-ca16-48ba-b074-65581be6f011</UETR>\n" +
            "\t\t\t\t\t\t</Refs>\n" +
            "\t\t\t\t\t\t<RltdAgts>\n" +
            "\t\t\t\t\t\t\t<InstgAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstgAgt>\n" +
            "\t\t\t\t\t\t\t<InstdAgt>\n" +
            "\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<MmbId>021040078</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t</InstdAgt>\n" +
            "\t\t\t\t\t\t\t<Prtry>\n" +
            "\t\t\t\t\t\t\t\t<Tp>RESP</Tp>\n" +
            "\t\t\t\t\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t\t\t\t\t</Agt>\n" +
            "\t\t\t\t\t\t\t</Prtry>\n" +
            "\t\t\t\t\t\t</RltdAgts>\n" +
            "\t\t\t\t\t\t<LclInstrm>\n" +
            "\t\t\t\t\t\t\t<Prtry>FDNA</Prtry>\n" +
            "\t\t\t\t\t\t</LclInstrm>\n" +
            "\t\t\t\t\t\t<RltdDts>\n" +
            "\t\t\t\t\t\t\t<AccptncDtTm>2023-06-04T10:55:26-04:00</AccptncDtTm>\n" +
            "\t\t\t\t\t\t\t<IntrBkSttlmDt>2023-06-04</IntrBkSttlmDt>\n" +
            "\t\t\t\t\t\t</RltdDts>\n" +
            "\t\t\t\t\t</TxDtls>\n" +
            "\t\t\t\t</NtryDtls>\n" +
            "\t\t\t</Ntry>\n" +
            "\t\t</Ntfctn>\n" +
            "\t</BkToCstmrDbtCdtNtfctn>\n" +
            "</Document>"
    },
    "camt.056.001.08": {
        "title": "camt.056.001.08",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.056.001.08\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.056.001.08 ReturnRequest_camt_056_001_08.xsd\">\n" +
            "\t<FIToFIPmtCxlReq>\n" +
            "\t\t<Assgnmt>\n" +
            "\t\t\t<Id>20230604011104238Sc01RTStep2MsgId</Id>\n" +
            "\t\t\t<Assgnr>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</Assgnr>\n" +
            "\t\t\t<Assgne>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t<MmbId>021040078</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</Assgne>\n" +
            "\t\t\t<CreDtTm>2023-06-04T13:55:26-04:00</CreDtTm>\n" +
            "\t\t</Assgnmt>\n" +
            "\t\t<Case>\n" +
            "\t\t\t<Id>20230604011104238Sc01Step1MsgIdDUP</Id>\n" +
            "\t\t\t<Cretr>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</Cretr>\n" +
            "\t\t</Case>\n" +
            "\t\t<Undrlyg>\n" +
            "\t\t\t<TxInf>\n" +
            "\t\t\t\t<OrgnlGrpInf>\n" +
            "\t\t\t\t\t<OrgnlMsgId>20230604011104238Sc01RTStep1MsgId</OrgnlMsgId>\n" +
            "\t\t\t\t\t<OrgnlMsgNmId>pacs.008.001.08</OrgnlMsgNmId>\n" +
            "\t\t\t\t\t<OrgnlCreDtTm>2023-06-04T10:55:26-04:00</OrgnlCreDtTm>\n" +
            "\t\t\t\t</OrgnlGrpInf>\n" +
            "\t\t\t\t<OrgnlInstrId>Scenario01InstrId001</OrgnlInstrId>\n" +
            "\t\t\t\t<OrgnlEndToEndId>Scenario01EtoEId001</OrgnlEndToEndId>\n" +
            "\t\t\t\t<OrgnlUETR>8a562c67-ca16-48ba-b074-65581be6f011</OrgnlUETR>\n" +
            "\t\t\t\t<OrgnlIntrBkSttlmAmt Ccy=\"USD\">51.74</OrgnlIntrBkSttlmAmt>\n" +
            "\t\t\t\t<OrgnlIntrBkSttlmDt>2023-06-04</OrgnlIntrBkSttlmDt>\n" +
            "\t\t\t\t<CxlRsnInf>\n" +
            "\t\t\t\t\t<Orgtr>\n" +
            "\t\t\t\t\t\t<Nm>Individual A</Nm>\n" +
            "\t\t\t\t\t\t<CtctDtls>\n" +
            "\t\t\t\t\t\t\t<MobNb>+1-6465315555</MobNb>\n" +
            "\t\t\t\t\t\t\t<PrefrdMtd>CELL</PrefrdMtd>\n" +
            "\t\t\t\t\t\t</CtctDtls>\n" +
            "\t\t\t\t\t</Orgtr>\n" +
            "\t\t\t\t\t<Rsn>\n" +
            "\t\t\t\t\t\t<Cd>DUPL</Cd>\n" +
            "\t\t\t\t\t</Rsn>\n" +
            "\t\t\t\t\t<AddtlInf>Already Paid. Ref 20230603011104238MsgIdFirstPayment</AddtlInf>\n" +
            "\t\t\t\t</CxlRsnInf>\n" +
            "\t\t\t</TxInf>\n" +
            "\t\t</Undrlyg>\n" +
            "\t</FIToFIPmtCxlReq>\n" +
            "</Document>"
    },
    "camt.060.001.05": {
        "title": "camt.060.001.05",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.060.001.05\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:camt.060.001.05 FedNow_AccountReportingRequest_camt_060_001_05.xsd\">\n" +
            "\t<AcctRptgReq>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>20230922122240120CITRrequest1</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-09-22T15:58:23+04:00</CreDtTm>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<RptgReq>\n" +
            "\t\t\t<Id>CITR</Id>\n" +
            "\t\t\t<ReqdMsgNmId>camt.052.001.08</ReqdMsgNmId>\n" +
            "\t\t\t<Acct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t<Id>000000000</Id>\n" +
            "\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t</Acct>\n" +
            "\t\t\t<AcctOwnr>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<MmbId>122240120</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</AcctOwnr>\n" +
            "\t\t</RptgReq>\n" +
            "\t</AcctRptgReq>\n" +
            "</Document>"
    },
    "head.001.001.02": {
        "title": "head.001.001.02",
        "msg": "<AppHdr xmlns=\"urn:iso:std:iso:20022:tech:xsd:head.001.001.02\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:head.001.001.02 BusinessApplicationHeader_head_001_001_02_20210617.xsd\">\n" +
            "\t<Fr>\n" +
            "\t\t<FIId>\n" +
            "\t\t\t<FinInstnId>\n" +
            "\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t<MmbId>021150706</MmbId>\n" +
            "\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t</FinInstnId>\n" +
            "\t\t</FIId>\n" +
            "\t</Fr>\n" +
            "\t<To>\n" +
            "\t\t<FIId>\n" +
            "\t\t\t<FinInstnId>\n" +
            "\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t</FinInstnId>\n" +
            "\t\t</FIId>\n" +
            "\t</To>\n" +
            "\t<BizMsgIdr>AADR</BizMsgIdr>\n" +
            "\t<MsgDefIdr>camt.052.001.08</MsgDefIdr>\n" +
            "\t<MktPrctc>\n" +
            "\t\t<Regy>www2.swift.com/mystandards/#/group/Federal_Reserve_Financial_Services/FedNow_Service</Regy>\n" +
            "\t\t<Id>frb.fednow.aad.01</Id>\n" +
            "\t</MktPrctc>\n" +
            "\t<CreDt>2023-09-02T19:31:13-04:00</CreDt>\n" +
            "</AppHdr>"
    },
    "pacs.002.001.10.fednow.payment.status": {
        "title": "pacs.002.001.10 (FedNow Payment Status)",
        "fedNowMessageType": "PACS_002_FEDNOW_PAYMENT_STATUS",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.002.001.10\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:pacs.002.001.10 FedNowPaymentStatus_pacs_002_001_10.xsd\">\n" +
            "\t<FIToFIPmtStsRpt>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>20230604021150706Sc01Step4AMsgId</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-06-04T10:55:32-04:00</CreDtTm>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<TxInfAndSts>\n" +
            "\t\t\t<OrgnlGrpInf>\n" +
            "\t\t\t\t<OrgnlMsgId>20230604011104238Sc01Step1MsgId</OrgnlMsgId>\n" +
            "\t\t\t\t<OrgnlMsgNmId>pacs.008.001.08</OrgnlMsgNmId>\n" +
            "\t\t\t\t<OrgnlCreDtTm>2023-06-04T10:55:26-04:00</OrgnlCreDtTm>\n" +
            "\t\t\t</OrgnlGrpInf>\n" +
            "\t\t\t<OrgnlInstrId>Scenario01InstrId001</OrgnlInstrId>\n" +
            "\t\t\t<OrgnlEndToEndId>Scenario01EtoEId001</OrgnlEndToEndId>\n" +
            "\t\t\t<OrgnlUETR>8a562c67-ca16-48ba-b074-65581be6f011</OrgnlUETR>\n" +
            "\t\t\t<TxSts>ACSC</TxSts>\n" +
            "\t\t\t<AccptncDtTm>2023-06-04T10:55:32-04:00</AccptncDtTm>\n" +
            "\t\t\t<FctvIntrBkSttlmDt>\n" +
            "\t\t\t\t<Dt>2023-06-04</Dt>\n" +
            "\t\t\t</FctvIntrBkSttlmDt>\n" +
            "\t\t\t<InstgAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>021150706</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstgAgt>\n" +
            "\t\t\t<InstdAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstdAgt>\n" +
            "\t\t</TxInfAndSts>\n" +
            "\t</FIToFIPmtStsRpt>\n" +
            "</Document>"
    },
    "pacs.002.001.10.participant.payment.status": {
        "fedNowMessageType": "PACS_002_PARTICIPANT_PAYMENT_STATUS",
        "title": "pacs.002.001.10 (Participant Payment Status)",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.002.001.10\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:pacs.002.001.10 ParticipantPaymentStatus_pacs_002_001_10.xsd\">\n" +
            "\t<FIToFIPmtStsRpt>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>20230604021040078Sc01Step3MsgId</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-06-04T10:55:30-04:00</CreDtTm>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<TxInfAndSts>\n" +
            "\t\t\t<OrgnlGrpInf>\n" +
            "\t\t\t\t<OrgnlMsgId>20230604011104238Sc01Step1MsgId</OrgnlMsgId>\n" +
            "\t\t\t\t<OrgnlMsgNmId>pacs.008.001.08</OrgnlMsgNmId>\n" +
            "\t\t\t\t<OrgnlCreDtTm>2023-06-04T10:55:26-04:00</OrgnlCreDtTm>\n" +
            "\t\t\t</OrgnlGrpInf>\n" +
            "\t\t\t<OrgnlInstrId>Scenario01InstrId001</OrgnlInstrId>\n" +
            "\t\t\t<OrgnlEndToEndId>Scenario01EtoEId001</OrgnlEndToEndId>\n" +
            "\t\t\t<OrgnlUETR>8a562c67-ca16-48ba-b074-65581be6f011</OrgnlUETR>\n" +
            "\t\t\t<TxSts>ACTC</TxSts>\n" +
            "\t\t\t<InstgAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>021040078</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstgAgt>\n" +
            "\t\t\t<InstdAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstdAgt>\n" +
            "\t\t</TxInfAndSts>\n" +
            "\t</FIToFIPmtStsRpt>\n" +
            "</Document>"
    },
    "pacs.004.001.10": {
        "title": "pacs.004.001.10",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.004.001.10\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:pacs.004.001.10 PaymentReturn_pacs_004_001_10.xsd\">\n" +
            "\t<PmtRtr>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>20230604021040078Sc01RTStep4MsgId</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-06-04T14:55:30-04:00</CreDtTm>\n" +
            "\t\t\t<NbOfTxs>1</NbOfTxs>\n" +
            "\t\t\t<SttlmInf>\n" +
            "\t\t\t\t<SttlmMtd>CLRG</SttlmMtd>\n" +
            "\t\t\t\t<ClrSys>\n" +
            "\t\t\t\t\t<Cd>FDN</Cd>\n" +
            "\t\t\t\t</ClrSys>\n" +
            "\t\t\t</SttlmInf>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<TxInf>\n" +
            "\t\t\t<OrgnlGrpInf>\n" +
            "\t\t\t\t<OrgnlMsgId>20230604011104238Sc01RTStep1MsgId</OrgnlMsgId>\n" +
            "\t\t\t\t<OrgnlMsgNmId>pacs.008.001.08</OrgnlMsgNmId>\n" +
            "\t\t\t\t<OrgnlCreDtTm>2023-06-04T10:55:26-04:00</OrgnlCreDtTm>\n" +
            "\t\t\t</OrgnlGrpInf>\n" +
            "\t\t\t<OrgnlInstrId>Scenario01InstrId001</OrgnlInstrId>\n" +
            "\t\t\t<OrgnlEndToEndId>Scenario01EtoEId001</OrgnlEndToEndId>\n" +
            "\t\t\t<OrgnlUETR>8a562c67-ca16-48ba-b074-65581be6f011</OrgnlUETR>\n" +
            "\t\t\t<OrgnlIntrBkSttlmAmt Ccy=\"USD\">51.74</OrgnlIntrBkSttlmAmt>\n" +
            "\t\t\t<OrgnlIntrBkSttlmDt>2023-06-04</OrgnlIntrBkSttlmDt>\n" +
            "\t\t\t<RtrdIntrBkSttlmAmt Ccy=\"USD\">51.74</RtrdIntrBkSttlmAmt>\n" +
            "\t\t\t<IntrBkSttlmDt>2023-06-04</IntrBkSttlmDt>\n" +
            "\t\t\t<ChrgBr>SLEV</ChrgBr>\n" +
            "\t\t\t<InstgAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>021040078</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstgAgt>\n" +
            "\t\t\t<InstdAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstdAgt>\n" +
            "\t\t\t<RtrChain>\n" +
            "\t\t\t\t<Dbtr>\n" +
            "\t\t\t\t\t<Pty>\n" +
            "\t\t\t\t\t\t<Nm>Corporation B</Nm>\n" +
            "\t\t\t\t\t\t<PstlAdr>\n" +
            "\t\t\t\t\t\t\t<PstBx>6001</PstBx>\n" +
            "\t\t\t\t\t\t\t<PstCd>60197</PstCd>\n" +
            "\t\t\t\t\t\t\t<TwnNm>Carol Stream</TwnNm>\n" +
            "\t\t\t\t\t\t\t<CtrySubDvsn>IL</CtrySubDvsn>\n" +
            "\t\t\t\t\t\t\t<Ctry>US</Ctry>\n" +
            "\t\t\t\t\t\t</PstlAdr>\n" +
            "\t\t\t\t\t</Pty>\n" +
            "\t\t\t\t</Dbtr>\n" +
            "\t\t\t\t<DbtrAcct>\n" +
            "\t\t\t\t\t<Id>\n" +
            "\t\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t\t<Id>56478374687</Id>\n" +
            "\t\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t\t</Id>\n" +
            "\t\t\t\t</DbtrAcct>\n" +
            "\t\t\t\t<DbtrAgt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t<MmbId>021040078</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<Nm>BankA</Nm>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</DbtrAgt>\n" +
            "\t\t\t\t<CdtrAgt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</CdtrAgt>\n" +
            "\t\t\t\t<Cdtr>\n" +
            "\t\t\t\t\t<Pty>\n" +
            "\t\t\t\t\t\t<Nm>Individual A</Nm>\n" +
            "\t\t\t\t\t\t<PstlAdr>\n" +
            "\t\t\t\t\t\t\t<StrtNm>Dream Road</StrtNm>\n" +
            "\t\t\t\t\t\t\t<BldgNb>450</BldgNb>\n" +
            "\t\t\t\t\t\t\t<Room>141</Room>\n" +
            "\t\t\t\t\t\t\t<PstCd>60532</PstCd>\n" +
            "\t\t\t\t\t\t\t<TwnNm>Lisle</TwnNm>\n" +
            "\t\t\t\t\t\t\t<CtrySubDvsn>IL</CtrySubDvsn>\n" +
            "\t\t\t\t\t\t\t<Ctry>US</Ctry>\n" +
            "\t\t\t\t\t\t</PstlAdr>\n" +
            "\t\t\t\t\t</Pty>\n" +
            "\t\t\t\t</Cdtr>\n" +
            "\t\t\t\t<CdtrAcct>\n" +
            "\t\t\t\t\t<Id>\n" +
            "\t\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t\t<Id>0031234567</Id>\n" +
            "\t\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t\t</Id>\n" +
            "\t\t\t\t</CdtrAcct>\n" +
            "\t\t\t</RtrChain>\n" +
            "\t\t\t<RtrRsnInf>\n" +
            "\t\t\t\t<Rsn>\n" +
            "\t\t\t\t\t<Cd>DUPL</Cd>\n" +
            "\t\t\t\t</Rsn>\n" +
            "\t\t\t\t<AddtlInf>Already Paid. Ref 20230603011104238MsgIdFirstPayment</AddtlInf>\n" +
            "\t\t\t</RtrRsnInf>\n" +
            "\t\t\t<OrgnlTxRef>\n" +
            "\t\t\t\t<PmtTpInf>\n" +
            "\t\t\t\t\t<LclInstrm>\n" +
            "\t\t\t\t\t\t<Prtry>FDNA</Prtry>\n" +
            "\t\t\t\t\t</LclInstrm>\n" +
            "\t\t\t\t</PmtTpInf>\n" +
            "\t\t\t</OrgnlTxRef>\n" +
            "\t\t</TxInf>\n" +
            "\t</PmtRtr>\n" +
            "</Document>"
    },
    "pacs.008.001.08": {
        "title": "pacs.008.001.08",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.008.001.08\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:pacs.008.001.08 CustomerCreditTransfer_pacs_008_001_08.xsd\">\n" +
            "\t<FIToFICstmrCdtTrf>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>20230604011104238Sc01Step1MsgId</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-06-04T10:55:26-04:00</CreDtTm>\n" +
            "\t\t\t<NbOfTxs>1</NbOfTxs>\n" +
            "\t\t\t<SttlmInf>\n" +
            "\t\t\t\t<SttlmMtd>CLRG</SttlmMtd>\n" +
            "\t\t\t\t<ClrSys>\n" +
            "\t\t\t\t\t<Cd>FDN</Cd>\n" +
            "\t\t\t\t</ClrSys>\n" +
            "\t\t\t</SttlmInf>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<CdtTrfTxInf>\n" +
            "\t\t\t<PmtId>\n" +
            "\t\t\t\t<InstrId>Scenario01InstrId001</InstrId>\n" +
            "\t\t\t\t<EndToEndId>Scenario01EtoEId001</EndToEndId>\n" +
            "\t\t\t\t<UETR>8a562c67-ca16-48ba-b074-65581be6f011</UETR>\n" +
            "\t\t\t</PmtId>\n" +
            "\t\t\t<PmtTpInf>\n" +
            "\t\t\t\t<LclInstrm>\n" +
            "\t\t\t\t\t<Prtry>FDNA</Prtry>\n" +
            "\t\t\t\t</LclInstrm>\n" +
            "\t\t\t\t<CtgyPurp>\n" +
            "\t\t\t\t\t<Prtry>CONS</Prtry>\n" +
            "\t\t\t\t</CtgyPurp>\n" +
            "\t\t\t</PmtTpInf>\n" +
            "\t\t\t<IntrBkSttlmAmt Ccy=\"USD\">51.74</IntrBkSttlmAmt>\n" +
            "\t\t\t<IntrBkSttlmDt>2023-06-04</IntrBkSttlmDt>\n" +
            "\t\t\t<ChrgBr>SLEV</ChrgBr>\n" +
            "\t\t\t<InstgAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstgAgt>\n" +
            "\t\t\t<InstdAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>021040078</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstdAgt>\n" +
            "\t\t\t<Dbtr>\n" +
            "\t\t\t\t<Nm>Individual A</Nm>\n" +
            "\t\t\t\t<PstlAdr>\n" +
            "\t\t\t\t\t<StrtNm>Dream Road</StrtNm>\n" +
            "\t\t\t\t\t<BldgNb>450</BldgNb>\n" +
            "\t\t\t\t\t<Room>141</Room>\n" +
            "\t\t\t\t\t<PstCd>60532</PstCd>\n" +
            "\t\t\t\t\t<TwnNm>Lisle</TwnNm>\n" +
            "\t\t\t\t\t<CtrySubDvsn>IL</CtrySubDvsn>\n" +
            "\t\t\t\t\t<Ctry>US</Ctry>\n" +
            "\t\t\t\t</PstlAdr>\n" +
            "\t\t\t</Dbtr>\n" +
            "\t\t\t<DbtrAcct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t<Id>0031234567</Id>\n" +
            "\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t</DbtrAcct>\n" +
            "\t\t\t<DbtrAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t<Nm>BankA</Nm>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</DbtrAgt>\n" +
            "\t\t\t<CdtrAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>021040078</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</CdtrAgt>\n" +
            "\t\t\t<Cdtr>\n" +
            "\t\t\t\t<Nm>Corporation B</Nm>\n" +
            "\t\t\t\t<PstlAdr>\n" +
            "\t\t\t\t\t<PstBx>6001</PstBx>\n" +
            "\t\t\t\t\t<PstCd>60197</PstCd>\n" +
            "\t\t\t\t\t<TwnNm>Carol Stream</TwnNm>\n" +
            "\t\t\t\t\t<CtrySubDvsn>IL</CtrySubDvsn>\n" +
            "\t\t\t\t\t<Ctry>US</Ctry>\n" +
            "\t\t\t\t</PstlAdr>\n" +
            "\t\t\t</Cdtr>\n" +
            "\t\t\t<CdtrAcct>\n" +
            "\t\t\t\t<Id>\n" +
            "\t\t\t\t\t<Othr>\n" +
            "\t\t\t\t\t\t<Id>56478374687</Id>\n" +
            "\t\t\t\t\t</Othr>\n" +
            "\t\t\t\t</Id>\n" +
            "\t\t\t</CdtrAcct>\n" +
            "\t\t\t<RmtInf>\n" +
            "\t\t\t\t<Strd>\n" +
            "\t\t\t\t\t<RfrdDocInf>\n" +
            "\t\t\t\t\t\t<Tp>\n" +
            "\t\t\t\t\t\t\t<CdOrPrtry>\n" +
            "\t\t\t\t\t\t\t\t<Cd>CINV</Cd>\n" +
            "\t\t\t\t\t\t\t</CdOrPrtry>\n" +
            "\t\t\t\t\t\t</Tp>\n" +
            "\t\t\t\t\t\t<Nb>INV34563</Nb>\n" +
            "\t\t\t\t\t\t<RltdDt>2023-06-01</RltdDt>\n" +
            "\t\t\t\t\t</RfrdDocInf>\n" +
            "\t\t\t\t</Strd>\n" +
            "\t\t\t</RmtInf>\n" +
            "\t\t</CdtTrfTxInf>\n" +
            "\t</FIToFICstmrCdtTrf>\n" +
            "</Document>"
    },
    "pacs.028.001.03": {
        "title": "pacs.028.001.03",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.028.001.03\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:pacs.028.001.03 PaymentStatusRequest_pacs_028_001_03.xsd\">\n" +
            "\t<FIToFIPmtStsReq>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>20230604011104238Sc05Step2MsgId</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-06-04T10:56:00-04:00</CreDtTm>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<TxInf>\n" +
            "\t\t\t<OrgnlGrpInf>\n" +
            "\t\t\t\t<OrgnlMsgId>20230604011104238Sc05Step1MsgId</OrgnlMsgId>\n" +
            "\t\t\t\t<OrgnlMsgNmId>pacs.008.001.08</OrgnlMsgNmId>\n" +
            "\t\t\t\t<OrgnlCreDtTm>2023-06-04T10:55:26-04:00</OrgnlCreDtTm>\n" +
            "\t\t\t</OrgnlGrpInf>\n" +
            "\t\t\t<OrgnlInstrId>Scenario05InstrId001</OrgnlInstrId>\n" +
            "\t\t\t<OrgnlEndToEndId>Scenario05EtoEId001</OrgnlEndToEndId>\n" +
            "\t\t\t<OrgnlUETR>8a562c67-ca16-48ba-b074-65581be6f011</OrgnlUETR>\n" +
            "\t\t\t<InstgAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>011104238</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstgAgt>\n" +
            "\t\t\t<InstdAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>021150706</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstdAgt>\n" +
            "\t\t</TxInf>\n" +
            "\t</FIToFIPmtStsReq>\n" +
            "</Document>"
    },
    "pacs.009.001.08": {
        "title": "pacs.009.001.08",
        "msg": "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.009.001.08\" xmlns:xsi=\"http://www.w3.org/2001/XMLSchema-instance\" xsi:schemaLocation=\"urn:iso:std:iso:20022:tech:xsd:pacs.009.001.08 FinancialInstitutionCreditTransfer_pacs_009_001_08.xsd\">\n" +
            "\t<FICdtTrf>\n" +
            "\t\t<GrpHdr>\n" +
            "\t\t\t<MsgId>20230713021307481FISc01Step1MsgId</MsgId>\n" +
            "\t\t\t<CreDtTm>2023-07-13T16:18:14-04:00</CreDtTm>\n" +
            "\t\t\t<NbOfTxs>1</NbOfTxs>\n" +
            "\t\t\t<SttlmInf>\n" +
            "\t\t\t\t<SttlmMtd>CLRG</SttlmMtd>\n" +
            "\t\t\t\t<ClrSys>\n" +
            "\t\t\t\t\t<Cd>FDN</Cd>\n" +
            "\t\t\t\t</ClrSys>\n" +
            "\t\t\t</SttlmInf>\n" +
            "\t\t</GrpHdr>\n" +
            "\t\t<CdtTrfTxInf>\n" +
            "\t\t\t<PmtId>\n" +
            "\t\t\t\t<InstrId>Scenario01FIInstrId001</InstrId>\n" +
            "\t\t\t\t<EndToEndId>Scenario01FIEtoEId001</EndToEndId>\n" +
            "\t\t\t\t<TxId>Scenario01FITxId001</TxId>\n" +
            "\t\t\t</PmtId>\n" +
            "\t\t\t<PmtTpInf>\n" +
            "\t\t\t\t<LclInstrm>\n" +
            "\t\t\t\t\t<Prtry>LMT1</Prtry>\n" +
            "\t\t\t\t</LclInstrm>\n" +
            "\t\t\t</PmtTpInf>\n" +
            "\t\t\t<IntrBkSttlmAmt Ccy=\"USD\">500000</IntrBkSttlmAmt>\n" +
            "\t\t\t<IntrBkSttlmDt>2023-07-13</IntrBkSttlmDt>\n" +
            "\t\t\t<InstgAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>021307481</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstgAgt>\n" +
            "\t\t\t<InstdAgt>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>231981435</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</InstdAgt>\n" +
            "\t\t\t<Dbtr>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>021307481</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t<Nm>Bank C</Nm>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</Dbtr>\n" +
            "\t\t\t<Cdtr>\n" +
            "\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t<ClrSysMmbId>\n" +
            "\t\t\t\t\t\t<ClrSysId>\n" +
            "\t\t\t\t\t\t\t<Cd>USABA</Cd>\n" +
            "\t\t\t\t\t\t</ClrSysId>\n" +
            "\t\t\t\t\t\t<MmbId>231981435</MmbId>\n" +
            "\t\t\t\t\t</ClrSysMmbId>\n" +
            "\t\t\t\t\t<Nm>BANK D</Nm>\n" +
            "\t\t\t\t</FinInstnId>\n" +
            "\t\t\t</Cdtr>\n" +
            "\t\t</CdtTrfTxInf>\n" +
            "\t</FICdtTrf>\n" +
            "</Document>"
    }
}